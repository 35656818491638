import {
  Select,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Card,
  Grid,
  Typography,
  Modal,
  Box,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../store";
import { setLogout, setMedicalCenter } from "../reducers/roleSlice";
import {
  graspKeys,
  medicalDropDown,
  removeItemFromStorage,
  walkKeys,
} from "../utils/constant";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getLastMonthData, getUserData } from "../utils/apiCalls";
import { toastError } from "../components/common/ToastMsg";
import { uniqBy } from "lodash";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Dashboard() {
  const dispatch = useDispatch<AppDispatch>();
  const userData = useSelector((state: AppState) => state.userInfo);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const [data, setData] = useState<any[]>();
  const [lastMonthData, setLastMonthData] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [medical, setMedical] = useState(userData?.medicalCenter);
  const [numberOfPatients, setNumberOfPatients] = useState(0);

  const handleClose = () => setOpenModal(false);
  const date: any = userData?.duration?.split("-");

  const [startDate, setStartDate] = useState(
    date[0] ? new Date(date[0]) : new Date()
  );
  const [endDate, setEndDate] = useState(
    date[1] ? new Date(date[1]) : new Date()
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await getUserData({
        medical_center: userData?.medicalCenter,
        start_date: moment(startDate).local().format("YYYY-MM-DD"),
        end_date: moment(endDate).local().format("YYYY-MM-DD"),
      });
      if (response.error) {
        setLoading(false);
        toastError("Algo salió mal");
        if (response.status === 401) {
          removeItemFromStorage("token");
          dispatch(setLogout());
          window.location.href = "/";
        }
        return;
      }
      if (response.data) {
        setLoading(false);

        if (response.data.length <= 0) {
          toastError(`Datos no disponibles para ${userData?.medicalCenter}`);
        }
        setData(response.data);
        let arr: string[] = [];
        const patients = response.data
          .map((i: any) => {
            if (arr.includes(i.medicalCenter)) {
              return 0;
            } else {
              arr.push(i.medicalCenter);
              return i.numberOfPatients;
            }
          })
          .reduce((acc: number, curr: number) => acc + curr, 0);
        setNumberOfPatients(patients);
      }
      const param =
        userData?.medicalCenter === "TOTAL Fesia PRO"
          ? ""
          : userData?.medicalCenter;
      const res = await getLastMonthData(param);
      if (res.error) {
        toastError("Algo salió mal");
        setLoading(false);
        return;
      }
      if (res.data) {
        setLoading(false);
        const hour: any = calculateSeconds(res.data);
        setLastMonthData(hour);
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setMedicalCenter(event.target.value));
  };

  const calculateSeconds = (data: any) => {
    let totalSeconds = 0;
    data?.forEach((current: any) => (totalSeconds += current?.stimulationTimeSeconds));
    return totalSeconds / 3600 < 1
      ? (totalSeconds / 3600).toFixed(1)
      : (totalSeconds / 3600).toFixed(1);
  };

  const calculateStepSum = () => {
    let totalStep = 0;
    data?.forEach(
      (current: any) =>
        current.sessionType === "walk" && (totalStep += current?.steps)
    );
    return totalStep;
  };

  const calculateGraspsSum = () => {
    let totalGrasps = 0;
    data?.forEach(
      (current: any) =>
        current.sessionType === "grasp" && (totalGrasps += current?.steps)
    );
    return totalGrasps;
  };

  const calculateWalkData = () => {
    let walkData = 0;
    data?.forEach((current: any) => {
      if (current.sessionType === "walk") {
        if (current.protocol) {
          walkData += current.stimulationTimeSeconds;
        }
      }
    });
    return (walkData / 3600).toFixed(1);
  };

  const calculateGraspData = () => {
    let graspData = 0;
    data?.forEach((current: any) => {
      if (current.sessionType === "grasp") {
        if (current.protocol) {
          graspData += current.stimulationTimeSeconds;
        }
      }
    });
    return (graspData / 3600).toFixed(1);
  };

  const filterByProtocols = () => {
    const protocolArr: any = {
      adl_training: 0,
      habituacion: 0,
      home: 0,
      repetitive_training: 0,
      tone_reduction: 0,
      senso: 0,
    };
    data?.forEach((item) => {
      if (item.sessionType === "grasp") {
        if (item.protocol) {
          Object.keys(protocolArr).forEach((key) => {
            if (item.protocol === key) {
              protocolArr[key] += item.stimulationTimeSeconds;
            }
          });
        }
      }
    });

    const keyValues = Object.keys(protocolArr).map((key) => {
      const newKey = graspKeys[key] || key;
      return { [newKey]: protocolArr[key] };
    });
    return Object.assign({}, ...keyValues);
  };

  const filterByWalkProtocols = (data: any) => {
    const protocolArr: any = {
      adl_training: 0,
      balance_manual: 0,
      balance_programado: 0,
      habituacion: 0,
      home: 0,
      marcha: 0,
      repetitive_training: 0,
      tone_reduction: 0,
      mywalk: 0,
      test: 0,
    };

    data?.forEach((item: any) => {
      if (item.sessionType === "walk") {
        if (item.protocol) {
          Object.keys(protocolArr).forEach((key) => {
            if (item.protocol === key) {
              protocolArr[key] += item.stimulationTimeSeconds;
            }
          });
        }
      }
    });
    const keyValues = Object.keys(protocolArr).map((key) => {
      const newKey = walkKeys[key] || key;
      return { [newKey]: protocolArr[key] };
    });
    return Object.assign({}, ...keyValues);
  };

  const middleData = () => (
    <Grid item xs={12} md={12}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={3}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Card className="dd_card card">
                <div>
                  <span className="dd_card_title">D. Emparejados</span>
                </div>
                <table className="table">
                  <tbody>
                    {uniqBy(data, "device")
                      ?.slice(0, 3)
                      ?.map((item: any) => (
                        <tr className="dd_table_row" key={Math.random()}>
                          <td className="dd_table_title"> {item.device}</td>
                          {/* <td className="dd_table_title_sm">08-05-2021</td> */}
                        </tr>
                      ))}
                    <tr>
                      {uniqBy(data, "device")?.length > 2 && (
                        <td colSpan={2}>
                          <div className="d-flex justify-content-end button">
                            <Button
                              onClick={handleOpen}
                              variant="outlined"
                              className="btn-small btn-primary-outline-300"
                            >
                              Ver todos
                            </Button>
                          </div>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
                <Modal
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="clinicModal">
                    <div className="clinicModal__header">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Dispositivos emparejados
                      </Typography>
                      <Button onClick={handleClose}>
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.5 6L6.5 18"
                            stroke="#878C91"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.5 6L18.5 18"
                            stroke="#878C91"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </div>
                    <div className="clinicModal__body">
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            {uniqBy(data, "device")?.map((item) => (
                              <tr className="dd_table_row" key={Math.random()}>
                                <td className="dd_table_title">
                                  {item.device}
                                </td>
                                {/* <td className="dd_table_title_sm">
                                  08-05-2021
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-end button">
                        <Button
                          onClick={handleClose}
                          variant="contained"
                          className="btn btn-primary"
                        >
                          Cerrar
                        </Button>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <Card className="dp_card card">
                <div style={{ width: "100%" }}>
                  <h3 className="dd_card_title">Dispositivos Grasp</h3>
                  <div className="dd_card_hl">
                    <div className="text-icon">
                      <div className="text">
                        <h3 className="card_text">{calculateGraspData()}</h3>
                        <p className="card_text_sm">Horas registradas</p>
                      </div>
                      <div className="icon">
                        <img src="../../../images/h.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className="dp_card last_card card">
                <div>
                  <h3 className="dd_card_title">Dispositivos Walk</h3>
                  <div className="dd_card_hl">
                    <div className="text-icon">
                      <div className="text">
                        <h3 className="card_text">{calculateWalkData()}</h3>
                        <p className="card_text_sm">Horas registradas</p>
                      </div>
                      <div className="icon">
                        <img src="../../../images/l.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const handleFilter = async () => {
    setLoading(true);
    setMedical(userData?.medicalCenter);
    if (startDate > endDate) {
      toastError("La fecha de inicio no debe exceder la fecha de finalización");
      setLoading(false);
      return;
    }
    if (moment(endDate).diff(startDate, "days") > 365) {
      toastError("El intervalo de fechas debe estar dentro de un año");
      setLoading(false);
      return;
    }
    const response = await getUserData({
      medical_center: userData?.medicalCenter,
      start_date: moment(startDate).local().format("YYYY-MM-DD"),
      end_date: moment(endDate).local().format("YYYY-MM-DD"),
    });
    if (response.error) {
      setLoading(false);
      toastError("Algo salió mal");
      if (response.status === 401) {
        removeItemFromStorage("token");
        dispatch(setLogout());
        window.location.href = "/";
      }
      return;
    }
    if (response.data) {
      if (response.data.length <= 0) {
        toastError(`Datos no disponibles para ${userData?.medicalCenter}`);
        setLoading(false);
      }
      setData(response.data);

      let arr: string[] = [];
      const patients = response.data
        .map((i: any) => {
          if (arr.includes(i.medicalCenter)) {
            return 0;
          } else {
            arr.push(i.medicalCenter);
            return i.numberOfPatients;
          }
        })
        .reduce((acc: number, curr: number) => acc + curr, 0);
      setNumberOfPatients(patients);
    }
    const param =
      userData?.medicalCenter === "TOTAL Fesia PRO"
        ? ""
        : userData?.medicalCenter;
    const res = await getLastMonthData(param);
    if (res.error) {
      toastError("Algo salió mal");
      setLoading(false);
      return;
    }
    if (res.data) {
      setLoading(false);
      const hour: any = calculateSeconds(res.data);
      setLastMonthData(hour);
      setLoading(false);
    }
  };

  return (
    <main className="main">
      <Header />
      <div className="main__wrapper">
        <div className="dashboard">
          <div className="dashboard__wrapper">
            <div className="custom-container">
              <div className="dashboard__wrapper--filter">
                <div className="filter-input form-content">
                  <FormControl fullWidth>
                    <InputLabel id="age-label">Selecciona un centro</InputLabel>
                    <Select
                      labelId="age-label"
                      id="age-select"
                      value={userData?.medicalCenter}
                      label="medical"
                      onChange={handleChange}
                      disabled={userData.role === "medical_center"}
                    >
                      {(userData.medicalDropdown || medicalDropDown).map(
                        (medical: { name: string; value: string }) => (
                          <MenuItem key={medical.name} value={medical.value}>
                            {medical.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <div className="daterange-picker-inputs ml-2">
                    <DatePicker
                      selected={startDate}
                      onChange={(date: Date): void => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      dateFormat={"yyyy/MM/dd"}
                      endDate={endDate}
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(date: Date): void => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat={"yyyy/MM/dd"}
                      minDate={startDate}
                    />
                  </div>
                </div>
                <div className="filter-button">
                  <Button
                    variant="contained"
                    className="lounch_button btn btn-primary"
                    onClick={handleFilter}
                  >
                    Filtrar
                  </Button>
                </div>
              </div>
              {loading ? (
                <CircularProgress className="center" />
              ) : (
                <>
                  <div className="dashboard__wrapper--user-info">
                    {medical === "TOTAL Fesia PRO" ? (
                      <div className="name">
                        <h3 className="title">{medical}</h3>
                      </div>
                    ) : (
                      <div className="avatar-name">
                        <div className="avatar">
                          <span className="circle">
                            <img src="../../../images/clinic-icon.svg" alt="" />
                          </span>
                        </div>
                        <div className="name">
                          <h3 className="title">{medical ?? "-"}</h3>
                          <p className="date">
                            {data?.length
                              ? `${moment(data?.[data?.length - 1]?.startTime)
                                  .local()
                                  .format("YYYY/MM/DD")} -
                              ${moment(data?.[0]?.startTime)
                                .local()
                                .format("YYYY/MM/DD")}
                            `
                              : ""}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="dashboard__wrapper--card">
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} md={12}>
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <Grid item xs={12} sm={6} md={3}>
                            <Card className="card">
                              <div className="text-icon">
                                <div className="text">
                                  <h3 className="card_text">
                                    {medical === "TOTAL Fesia PRO"
                                      ? numberOfPatients
                                      : data?.[0]?.numberOfPatients ?? "-"}
                                  </h3>
                                  <p className="card_text_sm">Pacientes</p>
                                </div>
                                <div className="icon">
                                  <img src="../../../images/p.png" alt="" />
                                </div>
                              </div>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Card className="card">
                              <div className="text-icon">
                                <div className="text">
                                  <h3 className="card_text">
                                    {data?.length ?? "-"}
                                  </h3>
                                  <p className="card_text_sm">Sesiones</p>
                                  <p className="card_text_sm">
                                    {
                                    `Última sesión: ${moment(data?.[0]?.startTime)
                                      .local()
                                      .format("YYYY/MM/DD")}`}
                                  </p>
                                </div>
                                <div className="icon">
                                  <img src="../../../images/mp.png" alt="" />
                                </div>
                              </div>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Card className="card">
                              <div className="text-icon">
                                <div className="text">
                                  <h3 className="card_text">
                                    {calculateSeconds(data)}
                                  </h3>
                                  <p className="card_text_sm">
                                    Horas de uso acumuladas
                                  </p>
                                </div>
                                <div className="icon">
                                  <img src="../../../images/ht.png" alt="" />
                                </div>
                              </div>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Card className="card last_card">
                              <div className="text-icon">
                                <div className="text">
                                  <h3 className="card_text">
                                    {lastMonthData ? lastMonthData : "-"}
                                  </h3>
                                  <p className="card_text_sm">
                                    Horas de uso en el último mes
                                  </p>
                                </div>
                                <div className="icon">
                                  <img src="../../../images/t.png" alt="" />
                                </div>
                              </div>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                      {userData?.role === "admin" &&
                      medical === "TOTAL Fesia PRO" ? (
                        <Grid item xs={12} md={12}>
                          <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Card className="dp_card card">
                                <div style={{ width: "100%" }}>
                                  <h3 className="dd_card_title">
                                    Dispositivos Grasp
                                  </h3>
                                  <div className="dd_card_hl">
                                    <div className="text-icon">
                                      <div className="text">
                                        <h3 className="card_text">
                                          {calculateGraspData()}
                                        </h3>
                                        <p className="card_text_sm">
                                          Horas registradas
                                        </p>
                                      </div>
                                      <div className="icon">
                                        <img
                                          src="../../../images/h.png"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="dispositive-progress">
                                  <div className="light-bg">
                                    <table className="label-progressbar">
                                      <tbody>
                                        {Object.entries(
                                          filterByProtocols()
                                        ).map(
                                          (
                                            proto: (string | number | any)[],
                                            i: number
                                          ) => {
                                            return (
                                              <tr key={proto?.[0]}>
                                                {proto?.[1] !== 0 && (
                                                  <td>
                                                    <p className="label">
                                                      {proto?.[0]}
                                                    </p>
                                                  </td>
                                                )}
                                                {proto?.[1] !== 0 && (
                                                  <td>
                                                    <div className="progressbar">
                                                      {proto?.[1] / 3600 < 1
                                                        ? (
                                                            proto?.[1] / 3600
                                                          ).toFixed(1)
                                                        : (
                                                            proto?.[1] / 3600
                                                          ).toFixed(1)}
                                                      <div className="progress-line">
                                                        <LinearProgress
                                                          variant="determinate"
                                                          value={
                                                            +(
                                                              (+(
                                                                proto?.[1] /
                                                                3600
                                                              ) *
                                                                100) /
                                                              +calculateGraspData()
                                                            ).toFixed(1)
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </td>
                                                )}
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr>
                                          <td>
                                            <div className="label">
                                              <div className="d-flex">
                                                <img
                                                  src="../../../images/down-arrow-icon.svg"
                                                  alt="arrow icon"
                                                  style={{ marginRight: "5px" }}
                                                />
                                                Total número de grasps
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <p className="progressbar">
                                              {calculateGraspsSum()}
                                            </p>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Card>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Card className="dp_card last_card card">
                                <div>
                                  <h3 className="dd_card_title">
                                    Dispositivos Walk
                                  </h3>
                                  <div className="dd_card_hl">
                                    <div className="text-icon">
                                      <div className="text">
                                        <h3 className="card_text">
                                          {calculateWalkData()}
                                        </h3>
                                        <p className="card_text_sm">
                                          Horas registradas
                                        </p>
                                      </div>
                                      <div className="icon">
                                        <img
                                          src="../../../images/l.png"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="dispositive-progress">
                                  <div className="light-bg">
                                    <table className="label-progressbar">
                                      <tbody>
                                        {Object.entries(
                                          filterByWalkProtocols(data)
                                        ).map(
                                          (
                                            proto: (string | number | any)[],
                                            i: number
                                          ) => {
                                            return (
                                              <tr key={proto?.[0]}>
                                                {proto?.[1] !== 0 && (
                                                  <td>
                                                    <p className="label">
                                                      {proto?.[0]}
                                                    </p>
                                                  </td>
                                                )}
                                                {proto?.[1] !== 0 && (
                                                  <td>
                                                    <div className="progressbar">
                                                      {proto?.[1] / 3600 < 1
                                                        ? (
                                                            proto?.[1] / 3600
                                                          ).toFixed(1)
                                                        : (
                                                            proto?.[1] / 3600
                                                          ).toFixed(1)}
                                                      <div className="progress-line">
                                                        <LinearProgress
                                                          variant="determinate"
                                                          value={
                                                            +(
                                                              (+(
                                                                proto?.[1] /
                                                                3600
                                                              ) *
                                                                100) /
                                                              +calculateWalkData()
                                                            ).toFixed(1)
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </td>
                                                )}
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr>
                                          <td>
                                            <div className="label">
                                              <div className="d-flex">
                                                <img
                                                  src="../../../images/down-arrow-icon.svg"
                                                  alt="arrow icon"
                                                  style={{ marginRight: "5px" }}
                                                />
                                                Total de pasos dados
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <p className="progressbar">
                                              {calculateStepSum()}
                                            </p>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        middleData()
                      )}
                    </Grid>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
