import React from "react";
import { useDispatch } from "react-redux";
// import "./header.css";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../../reducers/roleSlice";
import { AppDispatch } from "../../store";
import { removeItemFromStorage } from "../../utils/constant";
function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const onClick = () => {
    removeItemFromStorage("token");
    dispatch(setLogout());
    navigate("/");
  };
  return (
    <header className="header">
      <nav className="header_content">
        <div className="logo_header">
          <img src="../../../images/logo_white.png" alt="fesia logo" />
        </div>
        <div onClick={onClick} className="logout">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 22H5C3.89543 22 3 21.1046 3 20V4C3 2.89543 3.89543 2 5 2H10"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 16L21 12L17 8"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21 12H9"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </nav>
    </header>
  );
}

export default Header;
