import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="error_block">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h1" style={{ color: "white" }}>
          404
        </Typography>
        <Typography
          variant="h6"
          style={{ color: "white", marginBottom: "20px" }}
        >
          The page you’re looking for doesn’t exist.
        </Typography>
        <Button
          variant="contained"
          className="login_button btn btn-primary"
          onClick={() => navigate("/")}
        >
          Go Back
        </Button>
      </Box>
    </div>
  );
}

export default NotFound;
