import Axios from "axios";
import { getItemFromStorage } from "../utils/constant";

export const baseUrl = process.env.REACT_APP_API_ENDPOINT;
export const axios = Axios.create();

axios.interceptors.request.use(
  function (config) {
    let authToken = {};
    const token = getItemFromStorage("token");

    if (token) {
      authToken.Authorization = `Bearer ${token}`;
    }
    return {
      ...config,
      headers: {
        ...config.headers,
        ...authToken,
      },
    };
  },
  function (error) {
    console.log("eror");
    return Promise.reject(error);
  }
);

// HTTP methods
export const get = (url, params) =>
  axios
    .get(baseUrl + url, { params })
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));

export const post = (url, payload, axiosConfig) =>
  axios
    .post(baseUrl + url, payload, axiosConfig)
    .then((response) => handleResponse(response))
    .catch((error) => {
      return handleError(error);
    });

export const remove = (url, payload) =>
  axios
    .delete(baseUrl + url, payload)
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));

export const put = (url, payload, hasBaseURL = false, axiosConfig) =>
  axios
    .put(!hasBaseURL ? baseUrl + url : url, payload, axiosConfig)
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));

// response handlers
const handleResponse = (response) => {
  return {
    error: false,
    data: response.data.data,
    status: response.data.status,
  };
};

export const handleError = (error) => {
  return {
    error: true,
    data: error.message,
    status: error.response.status,
  };
};
