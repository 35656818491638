import { get, post } from "../api/network";

export const loginUser = async (data: any) => {
  let url = "api/users/login";
  let res = await post(url, data);
  return res;
};

export const getUserProfile = async () => {
  let url = `api/users/me`;
  let res = await get(url);
  return res;
};

export const getMedicalCenters = async () => {
  let url = "api/medicalcenters/get_list";
  let res = await get(url);
  return res;
};

export const getUserData = async (data: any) => {
  let url = `api/medicalcenters/get_data`;
  let res = await post(url, data);
  return res;
};

export const getLastMonthData = async (params: string) => {
  let url = `api/medicalcenters/get_month_data?center=${params}`;
  let res = await get(url);
  return res;
};
