import { Alert, Snackbar } from "@mui/material";
import moment from "moment";

export const medicalDropDown = [
  { name: "Total Fesia PRO", value: "Total Fesia PRO" },
  { name: "Nombre del centro", value: "Nombre del centro" },
  { name: "Nombre del centro", value: "Nombre del centro" },
];

export const getItemFromStorage = (key: string) => {
  const item = localStorage.getItem(key);
  let result = null;
  try {
    result = item ? JSON.parse(item) : null;
  } catch {
    result = item;
  }
  return result;
};

export const setItemInStorage = (name: string, data: any) => {
  localStorage.setItem(name, JSON.stringify(data));
};

export const removeItemFromStorage = (name: string) => {
  localStorage.removeItem(name);
};

export const toastMsg = (type: any, msg: string) => (
  <Snackbar open={true} autoHideDuration={6000}>
    <Alert severity={type}>{msg}</Alert>
  </Snackbar>
);

export const convertToLocale = (date: any): any => {
  return moment(date).local().format("YYYY-MM-DD");
};

export const walkKeys: any = {
  habituacion: "Habituation",
  adl_training: "ADLs training",
  tone_reduction: "Tone reduction",
  repetitive_training: "Repetitive task training",
  home: "Home",
  marcha: "Gait",
  balance_manual: "Manual perturbations",
  balance_programado: "Programmed perturbations",
  mywalk: "MyWalk",
  test: "Test"
};

export const graspKeys: any = {
  habituacion: "Habituation",
  adl_training: "ADLs training",
  tone_reduction: "Tone reduction",
  repetitive_training: "Repetitive task training",
  home: "Home",
  senso: "Senso",
  test: "Test"
};
