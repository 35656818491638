import { TextField, Button } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastError, toastSuccess } from "../components/common/ToastMsg";
import { setLoggedIn, setUserRole } from "../reducers/roleSlice";
import { AppDispatch } from "../store";
import { loginUser } from "../utils/apiCalls";
import { setItemInStorage } from "../utils/constant";
function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [form, setForm] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const name = e.target.name;
    const val = e.target.value;
    setForm({ ...form, [name]: val });
  };

  const onLogin = async () => {
    if (form.email.trim() === "" || form.password.trim() === "") {
      setLoading(false);
      toastError("Por favor Ingrese los detalles");
      return;
    }
    setLoading(true);
    const response = await loginUser(form);
    if (response.error) {
      if (response?.status === 400) {
        setLoading(false);
        toastError("usuario o contraseña invalido");
        return;
      }
    }
    if (response.data) {
      setLoading(false);
      dispatch(setUserRole(response.data.role || ""));
      dispatch(setLoggedIn(true));
      setItemInStorage("token", response.data.token);
      toastSuccess("Iniciado sesión con éxito");
      navigate("/launch");
    }

    setLoading(false);
  };

  return (
    <div className="login_main">
      <div className="login_content">
        <div className="card">
          <div className="login_logo">
            <img src="../../../images/logo.png" alt="logo" />
          </div>
          <form className="form">
            <div className="form-group">
              <TextField
                id="email"
                className="login_text form-control"
                autoComplete="on"
                label="Nombre"
                variant="standard"
                name="email"
                value={form.email}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="form-group">
              <TextField
                id="password"
                className="login_text form-control"
                label="Contraseña"
                variant="standard"
                name="password"
                type="password"
                value={form.password}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="button justify-content-end d-flex">
              <Button
                variant="contained"
                onClick={(e) => {
                  onLogin();
                }}
                className="login_button btn btn-primary"
                disabled={loading}
              >
                Iniciar sesión
              </Button>
            </div>
          </form>
        </div>
        <div className="bottom-text">
          <h4 className="dim">¿Problemas para iniciar sesión?</h4>
          <h4 className="focused">Recordar contraseña</h4>
        </div>
      </div>
    </div>
  );
}

export default Login;
