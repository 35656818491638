import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const UserRoleSlice = createSlice({
  name: "userRole",
  initialState: {
    role: "",
    duration: "",
    medicalCenter: "",
    medicalDropdown: [] as { name: string; value: string }[],
    loggedIn: false as Boolean,
  },
  reducers: {
    setUserRole: (state, action: PayloadAction<string>) => {
      return { ...state, role: action.payload };
    },
    setDuration: (state, action: PayloadAction<string>) => {
      return { ...state, duration: action.payload };
    },
    setMedicalCenter: (state, action: PayloadAction<string>) => {
      return { ...state, medicalCenter: action.payload };
    },
    setMedicalDropdown: (
      state,
      action: PayloadAction<{ name: string; value: string }[]>
    ) => {
      return { ...state, medicalDropdown: action.payload };
    },
    setLogout: (state): any => {
      return {
        ...state,
        loggedIn: false,
      };
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      return { ...state, loggedIn: action.payload };
    },
  },
  extraReducers: {},
});

export const {
  setUserRole,
  setDuration,
  setMedicalCenter,
  setMedicalDropdown,
  setLogout,
  setLoggedIn,
} = UserRoleSlice.actions;

export default UserRoleSlice.reducer;
