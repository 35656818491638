import {
  Select,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../store";
import {
  setDuration,
  setMedicalCenter,
  setMedicalDropdown,
} from "../reducers/roleSlice";
import { medicalDropDown } from "../utils/constant";
import { getMedicalCenters, getUserProfile } from "../utils/apiCalls";
import { toastError } from "../components/common/ToastMsg";
import moment from "moment";

function Launch() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userData = useSelector((state: AppState) => state.userInfo);

  const [medical, setMedical] = useState("");
  const [dropDown, setDropDown] = useState(medicalDropDown);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    async function fetchCenter() {
      const response = await getMedicalCenters();
      if (response.error) {
        toastError("Algo salió mal");
        return;
      }
      if (response.data) {
        const data: { name: string; value: string }[] = [];
        response.data.forEach((d: string) => data.push({ name: d, value: d }));
        setDropDown(data);
        dispatch(setMedicalDropdown(data));
      }
    }
    fetchCenter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchProfile() {
      const response = await getUserProfile();
      if (response.error) {
        toastError("Algo salió mal");
        return;
      }
      if (response.data) {
        setMedicalCenter(response.data.medical_center);
        setMedical(response.data.medical_center);
        dispatch(setMedicalCenter(response.data.medical_center));
      }
    }
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setMedical(event.target.value);
    dispatch(setMedicalCenter(event.target.value));
  };

  const onClick = () => {
    if (medical === "") {
      toastError("Por favor seleccione un Centro Médico");
      return;
    }
    if (startDate > endDate) {
      toastError("La fecha de inicio no debe exceder la fecha de finalización");
      return;
    }
    if (moment(endDate).diff(startDate, "days") > 365) {
      toastError("El intervalo de fechas debe estar dentro de un año");
      return;
    }
    dispatch(setDuration(`${startDate}-${endDate}`));
    navigate("/dashboard");
  };

  return (
    <main className="main">
      <Header></Header>
      <div className="main__wrapper">
        <div className="lounchpad">
          <div className="lounchpad__form">
            <div className="lounchpad__form--heading">Filtrar información</div>
            <div className="lounchpad__form--content form-content">
              <div className="form-group">
                <FormControl fullWidth>
                  <InputLabel id="age-label">Selecciona un centro</InputLabel>
                  <Select
                    labelId="medical_label"
                    id="age-select"
                    value={medical}
                    label="medical"
                    onChange={handleChange}
                    disabled={userData.role === "medical_center"}
                  >
                    {dropDown.map((medical: any) => (
                      <MenuItem key={medical.name} value={medical.value}>
                        {medical.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="form-group">
                <div className="daterange-picker-inputs-launch">
                  <DatePicker
                    selected={startDate}
                    onChange={(date: Date): void => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    dateFormat={"yyyy/MM/dd"}
                    endDate={endDate}
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date: Date): void => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat={"yyyy/MM/dd"}
                    minDate={startDate}
                  />
                </div>
              </div>
              <div className="button justify-content-end">
                <Button
                  variant="contained"
                  onClick={onClick}
                  className="lounch_button btn btn-primary"
                >
                  Filtrar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Launch;
