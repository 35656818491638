import "./assets/styles/main.scss";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import { useSelector } from "react-redux";
import { AppState } from "./store";
import Launch from "./pages/Launch";

function App() {
  const userData = useSelector((state: AppState) => state.userInfo);

  return (
    <BrowserRouter>
      <Routes>
        {userData.loggedIn ? (
          <>
            <Route path="/launch" element={<Launch />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<Dashboard />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
